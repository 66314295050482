import "./EDocumentsAndSignatures.scss"
import { useEffect, useState } from "react";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import TPCoreAPIEDocumentsAndSignatures from "../tp-core-api/TPCoreAPIEDocumentsAndSignatures";
import { GetHcpDocumentsAndSignaturesResponse, SignableDocument, SignedDocument } from "../tp-core-types/GetHcpDocumentsAndSignaturesResponse"
import displayAsDateAndTime from "../utilities/displayAsDateAndTime";
import  pdf_icon  from "../../src/assets/pdf_icon.png"
import { useNavigate } from "react-router-dom";
import TPCoreAPIDocuments from "../tp-core-api-common/TPCoreAPIDocuments";
import { useError } from "../utilities/context/useError";
import EDocumentsAndSignaturesDesktop from "./EDocumentsAndSignaturesDesktop";
import EDocumentsAndSignaturesMobile from "./EDocumentsAndSignaturesMobile";


export default function EDocumentsAndSignatures() {
    const navigate = useNavigate();
    const {setError} = useError()
    const isMobile = window.matchMedia("(max-width: 1249px)").matches;
    const [documents, setDocuments] = useState({
        signableDocuments: [],
        signedDocumentsForCurrentAssignment: [],
        signedDocumentsForPastAssignments: []
    } as GetHcpDocumentsAndSignaturesResponse);

    async function openFile(contractNumber: number, documentMasterId: number){
        let waitingPage;
        try{
            waitingPage = window.open("document-loading.html")!;
            const myData= (await TPCoreAPIDocuments.getSignedContractDocument(contractNumber, documentMasterId));
            const file = window.URL.createObjectURL(myData);
            waitingPage.location.href = file;
        }
        catch{
            setError(new Error("Something went wrong when opening the file."))
            waitingPage?.close();
        }
    }
    
    async function handleGetContract(contractNumber: number, documentMasterId: number){
        navigate("/review-and-sign-contract", {state: {contractId: contractNumber, documentMasterId: documentMasterId, returnLocation: "/e-documents-and-signatures"}});
    }

    useEffect(() => {
        getDocumentLists();
    }, [])

    const getDocumentLists = async () => {
        TPCoreAPIEDocumentsAndSignatures.getHcpDocumentsAndSignatures().then(ds => {
            setDocuments(ds)
        }).catch( e => {setError(new Error("Something went wrong when retrieving files."))})
    }


    return(
        <>
            <div id="EDocumentsAndSignatures">
                <GenericPageHeader title="E-Documents and Signatures"></GenericPageHeader>
                <div className="page-container">
                    {
                        isMobile 
                            ? <EDocumentsAndSignaturesMobile handleGetContract={handleGetContract} openFile={openFile} documents={documents}/> 
                            : <EDocumentsAndSignaturesDesktop handleGetContract={handleGetContract} openFile={openFile} documents={documents}/> 
                    }
                </div>
            </div>
            
        </>
    )
}

