import { useEffect, useState } from "react";
import GenericPageHeader from "../generic-page-header/GenericPageHeader"
import "./ReferAFriend.scss"
import { Profession } from "../tp-core-types/JobSearchOptionsModel";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { Validator } from "../validator/Validator";
import { ReferAFriendPayload } from "../tp-core-types/ReferAFriendPayload";
import { ReferralSpecialty } from "../tp-core-types/ReferralSpecialty";
import greenCheckmark from  "../assets/referral_green_checkmark.svg";

export default function ReferAFriend(){
    const [specialties, setSpecialties] = useState<ReferralSpecialty[]>([]);
    const [professions, setProfessions] = useState<Profession[]>([]);
    const [selectedProfessionId, setSelectedProfessionId] = useState<string>("13");
    const [selectedSpecialtyId, setSelectedSpecialtyId] = useState<string>("0");

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const getReferralSpecialties = async() => {
        const specialtyReturn = await TPCoreAPI.getSpecialties();
        setSpecialties(specialtyReturn);
        return;
    }

    const getReferralProfessions = async() => {
        const professionReturn = await TPCoreAPI.getProfessions();
        setProfessions(professionReturn);
    }

    const handleSave = () => {
        const payload: ReferAFriendPayload = {
            FirstName: firstName,
            LastName: lastName,
            PhoneNumber: phoneNumber,
            DisciplineId: parseInt(selectedProfessionId),
            SpecialtyId: parseInt(selectedSpecialtyId)
        }
        try{
            TPCoreAPI.postReferralData(payload);
            setIsSubmitted(true);
        }
        catch{
            
        }
    }
    useEffect(() => {
        setIsDisabled(true);
        if(firstName !=="" && lastName!=="" && phoneNumberIsValid && selectedProfessionId !== ""){
            setIsDisabled(false);
        }

    }, [firstName, lastName, selectedProfessionId, phoneNumberIsValid]);

    useEffect(() => {
        getReferralSpecialties();
        getReferralProfessions();
    }, []);
    
    useEffect(() => {
        setPhoneNumberIsValid(Validator.validatePhoneNumber(phoneNumber));
    }, [phoneNumber]);

    useEffect(() => {
        if(isSubmitted){
            setTimeout(()=> { 
                resetForm();
                setIsSubmitted(false); }, 2000)
        }
    }, [isSubmitted]);
    
    const resetForm = ()=> {
        setFirstName("");
        setLastName("");
        setPhoneNumber("");
        setSelectedProfessionId("13");
        setSelectedSpecialtyId("0");
    }
    return(
        <div id="refer-a-friend">
            <GenericPageHeader title="Refer A Friend"></GenericPageHeader>
            {!isSubmitted &&
            <div className="referral-container" data-testid="referral-container-id">
                <div className="referral-header"> If you refer a friend who completes an assignment for TRS, you could earn up to $1000 per referral! Feel free to enter as many as you want.</div>
                <div className="referral-form">
                    <div className="referral-rows">
                        <div className="referral-row1">
                            <div className="referral-input-container">
                                <label htmlFor="referral_FirstName">Referral's First Name*</label>
                                <input id="referral_FirstName" type="text" onChange={(e) => {setFirstName(e.target.value.trim())}} value={firstName}></input>
                            </div>
                            
                            <div className="referral-input-container">
                                <label htmlFor="referral_LastName">Referral's Last Name*</label>
                                <input id="referral_LastName" type="text" onChange={(e) => {setLastName(e.target.value.trim())}} value={lastName}></input>
                            </div>
                            <div className="referral-input-container">
                                <label htmlFor="referral_Phone">Referral's Phone Number*</label>
                                <input maxLength={10} id="referral_Phone" type="text" onChange={(e) => {setPhoneNumber(e.target.value)}} value={phoneNumber}></input>
                            </div>
                        </div>
                        <div className="referral-row2">
                            <div className="referral-input-container">
                                    <label htmlFor="referral_Profession">Referral's Profession*</label>
                                    <select value={selectedProfessionId} onChange={(e)=> {setSelectedProfessionId(e.target.value)}} className="referral-select"id="referral_Profession">
                                        <option value="">Select Profession</option>
                                        {
                                            professions.sort((a,b) => {
                                                if(a.name > b.name) return 1;
                                                if(b.name > a.name) return -1;
                                                return 0;
                                            }).map(p => {
                                                return (<option value={p.id}>{p.name}</option>)
                                            })
                                        }
                                    </select>
                            </div>
                            <div className="referral-input-container">
                                <label htmlFor="referral_Specialty">Referral's Primary Specialty</label>
                                <select value={selectedSpecialtyId} className="referral-select" id="referral_Specialty" onChange={(e) => {setSelectedSpecialtyId(e.target.value)}}>
                                    <option value={0}>Select Specialty</option>
                                    {specialties.map(s=> {
                                        return (<option value={s.specialtyId}>{s.name}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="referral-button-container">
                    <button id="referral-submit-button" disabled={isDisabled} data-testid="submit-button" onClick={handleSave}>Submit Referral</button>
                </div>
            </div>
        }
        {isSubmitted && 
            <div  className="referral-green-check-mark" data-testid="referral-green-check-mark">
                <img src={greenCheckmark} alt="Success"></img>
                <div className="referral-success-text">Referral Submitted</div>
            </div>}
        </div>
        
    )
}