import displayAsDate from "../utilities/displayAsDate";
import displayAsMoney from "../utilities/displayAsMoney";
import getShiftDisplay from "../utilities/getShiftDisplay";
import calendar_icon from "../assets/calendar_icon.png";
import clock_icon from "../assets/clock_icon.png";
import dollar_icon from "../assets/dollar_icon.png";
import hospital_icon from "../assets/hospital_icon.png";
import people_icon from "../assets/people_icon.png";
import left_chevron_icon from "../assets/left_chevron_icon.svg";
import "./JobSearchDetails.scss";
import { JobDetailsModel } from "../tp-core-types/JobDetailsModel";
import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import JobSearchPayDetails from "../job-search-pay-details/JobSearchPayDetails";
import JobSearchFacilityDetails from "../job-search-facility-details/JobSearchFacilityDetails";
import LoadingOverlay from "../loading-overlay/LoadingOverlay";
import JobSearchMinimumRequirementsDetails from "../job-search-minimum-requirements-details/JobSearchMinimumRequirementsDetails";
import JobSearchCityInfoDetails from "../job-search-city-info-details/JobSearchCityInfoDetails";
import JobSearchBenefitInfoDetails from "../job-search-benefit-details/JobSearchBenefitInfoDetails";
import JobSearchAboutTrsDetails from "../job-search-about-trs-details/JobSearchAboutTrsDetails";
import JobAltMainHeader from "../job-alt-main-header/JobAltMainHeader";
import LoginModal from "../login-modal/LoginModal";
import AddToFavoriteButton from "../add-to-favorite-button/AddToFavoriteButton";
import StartApplicationButton from "../start-application/start-application-button/StartApplicationButton";
import StartApplicationModal from "../start-application/start-application-modal/StartApplicationModal";
import isArchivedJob from "../utilities/isArchivedJob";
import { Session } from "../utilities/Session";
import JobClosedHeader from "../job-closed-header/JobClosedHeader";
import UserInfoModel, { UserInfo } from "../tp-core-types/UserInfoModel";
import { StartApplicationResponse } from "../tp-core-types/StartApplicationResponse";
import { RequestedTimeOff } from "../tp-core-types/RequestedTimeOff";
import { SubmittedJobDetail } from "../tp-core-types/SubmittedJobDetail";
import SubmittedJobDetails from "../submitted-job-details/SubmittedJobDetails";
import { useError } from "../utilities/context/useError";
import { JobPostingScriptCreator } from "../job-posting-script-creator/JobPostingScriptCreator";
import { PloppableContext } from "../utilities/context/PloppableContextProvider";

type Props = {
  jobId: number;
  returnToResultsCallback?: () => void;
  neverShowActionButtons: boolean;
  submittedJobDetails: SubmittedJobDetail | null;
  isJobSearchDetailsPloppable?: boolean;
};

function addDefaultSrc(event: SyntheticEvent<HTMLImageElement, Event>) {
  event.currentTarget.src =
    "https://apptest.trshealthcare.com/api/avatar/avatarbyid/933923/0/jpg";
}

export default function JobSearchDetails(props: Props) {
  const [jobDetails, setJobDetails] = useState<JobDetailsModel | null>(null);
  const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false)
  const [showStartApplicationModalVisibility, setShowStartApplicationModalVisibility] = useState(false)
  const [searchForMoreJobsVisibility, setSearchForMoreJobsVisibility] = useState(false)
  const [loginCallback, setLoginCallback] = useState<() => Promise<void>>(() => new Promise<void>(() => {return}));
  const userInfoModel = useContext(UserInfoModel) 
  const [applicationResponse, setApplicationResponse] = useState<StartApplicationResponse | null>(null)

  const [applicationProcessing, setApplicationProcessing] = useState(false)
  const isMobile = window.matchMedia("(max-width: 1250px)").matches;
  const {setError} = useError()
  const isPloppable = useContext(PloppableContext).isPloppable;

  useEffect(() => {
    if (!applicationResponse || !jobDetails) {
      return
    }
    setApplicationProcessing(false)
    if(applicationResponse.jobId === props.jobId){
      setJobDetails({
        ...jobDetails, 
        interested: applicationResponse.isInterested
      });
    }
  }, [applicationResponse])

  useEffect(() => {
    if (props.isJobSearchDetailsPloppable) {
     document.body.style.overflow = showStartApplicationModalVisibility || showLoginModalVisibility || searchForMoreJobsVisibility ? "hidden" : "scroll";
     document.body.style.position = showStartApplicationModalVisibility || showLoginModalVisibility || searchForMoreJobsVisibility ? "fixed" : "unset";
    }
  }, [showStartApplicationModalVisibility, showLoginModalVisibility, props.isJobSearchDetailsPloppable, searchForMoreJobsVisibility])

  useEffect(() => {
    getJobDetailsResults(props);
    setApplicationProcessing(false);
  }, [props, userInfoModel.token]);

  const postFavoriteJob = async (isFavorite: boolean) => {
    if(!jobDetails){
      return
    }
    setShowLoginModalVisibility(false)
  
    try{
      const response = await TPCoreAPI.postFavoriteJob(jobDetails.jobId, isFavorite)
      if(response.jobId === jobDetails.jobId){
        setJobDetails({
          ...jobDetails, 
          favorited: response.isFavorite
        });
      }
    }
    catch{
      setJobDetails({
        ...jobDetails
      });
      setError(new Error("It looks like something went wrong. Please try again."))
    }
    
  }  

  const postStartApplication = async (jobId: number, isInsterested: boolean, desiredStartDate: Date, otherRequests: string, bestContactTime: string, requestedTimeOff: RequestedTimeOff[], userInfo: UserInfo | undefined) => {
    if (!jobDetails) {
      return 
    }
    
    setShowLoginModalVisibility(false)
    setApplicationProcessing(true)
    
    let response;
    try {
      
      response = await TPCoreAPI.postStartApplication(jobId, isInsterested, desiredStartDate, otherRequests, bestContactTime, requestedTimeOff)
      setApplicationResponse(response)
      if (userInfo && !userInfo.applicationComplete)
        window.location.href = process.env.REACT_APP_PORTAL_APP_BASE_URL + "/apply-now";
    } catch {
      setApplicationProcessing(false)
      setError(new Error("It looks like something went wrong. Please try again."))
    }
  }

  const setGoogleTags = (jobDetailsReturned: JobDetailsModel) => {
    document.body.appendChild(JobPostingScriptCreator.getScript(jobDetailsReturned));
  }

  const getJobDetailsResults = async (props: Props) => {
    try {
      const jobDetailsReturned = await TPCoreAPI.getJobDetails(props.jobId);
      setJobDetails(jobDetailsReturned);
      if (process.env.REACT_APP_ENABLE_GOOGLE_TAGS==="true" && isPloppable) {
        setGoogleTags(jobDetailsReturned);
      }
    } catch (err) {
      setJobDetails(null);
    }
  };
  const containerRef = useRef<HTMLDivElement>(null)
  const topDivRef = useRef<HTMLDivElement>(null)
  const altDivRef = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (containerRef.current && topDivRef.current && altDivRef.current) {
      const containerTop = containerRef.current.getBoundingClientRect().top
      const topDivBottom = topDivRef.current.getBoundingClientRect().bottom
      if(topDivBottom <= containerTop){
        showAltDiv(altDivRef.current);
      }else {
        hideAltDiv(altDivRef.current);
      }
    }
  };

  const showAltDiv = (altDiv: HTMLDivElement) => {
    altDiv.style.top = '0px';
  } ;

  const hideAltDiv = (altDiv : HTMLDivElement) => {
    const calculated_top = -1*Number(altDiv.getBoundingClientRect().height);
    altDiv.style.top = `${calculated_top}px`;
  }

  const showLoginDialog = (afterLogin: () => Promise<void>)=>{
    if(! Session.isHcpLoggedIn()) {
      setLoginCallback(()=> afterLogin);
      setShowLoginModalVisibility(true);
    }
  }

  const showStartApplicationModal = ()=> {
      setShowStartApplicationModalVisibility(true)
  }

  return (<>
    <div id="JobSearchDetails"> 
      {jobDetails && 
        <StartApplicationModal 
          key={props.jobId}
          jobDetails={jobDetails} 
          isVisible={showStartApplicationModalVisibility} 
          setVisibility={(value: boolean) => {setShowStartApplicationModalVisibility(value)}} 
          showLoginDialog={showLoginDialog}
          postStartApplication={postStartApplication}
        />
      }
      
      {applicationProcessing && <LoadingOverlay></LoadingOverlay>}
      {!jobDetails && <LoadingOverlay></LoadingOverlay>}
      {jobDetails && (
        <div className="right-pane-with-secondary-header" data-testid="job-details">
          { isArchivedJob(jobDetails) && (!isMobile || isPloppable) &&
            <div className="archived-job-header" data-testid="archived-job-header">
                <JobClosedHeader setSearchForMoreJobsVisibility={setSearchForMoreJobsVisibility}/>
            </div>
          }
          <div ref={containerRef} onScroll={handleScroll} className={isArchivedJob(jobDetails) ? "jobDetailListStyle archived" : "jobDetailListStyle"} data-testid="job-details-list-style">
            {!isPloppable && props.returnToResultsCallback && 
              <div className="mobile-navigation-bar">
                <span
                  className="returnToResults"
                  data-testid="return-to-results"
                  onClick={props.returnToResultsCallback}
                >
                  <img src={left_chevron_icon} alt="return to results"></img>
                  {"Results"}
                </span>
              </div>
            }
            <div className="jobMainHeader">
              <span className="jobMainHeaderTitle">{`${jobDetails?.profession} (${jobDetails?.specialty})`}</span>
              <span className="jobMainHeaderSeparator">{` - `}</span>
              <span className="jobMainHeaderSubtitle">{`${jobDetails?.facility.city}, ${jobDetails?.facility.state}`}</span>
            </div>

            <div className="jobMainHeaderJobNumber">
              <span className="jobMainHeaderJobNumberText">{`Job Number: ${jobDetails?.jobId}`}</span>
            </div>
            <div className="rightPaneItem">
              <div className="facilityImageContainer">
                <img
                  className="facilityImage"
                  src={jobDetails?.facility.imageUrl}
                  onError={addDefaultSrc}
                  alt="facility"
                  data-testid="facility-image"
                />
              </div>
              <div className="rightPaneDetails">
                <div className="detail-title">
                  <img className="detail-icon" src={hospital_icon} alt=""></img>
                  <h3 className="detail-title-text">
                    {jobDetails?.facility.facilityName}
                  </h3>
                </div>
                <div className="detail-subtitle">
                  <img className="detail-icon" src={dollar_icon} alt=""></img>
                  <h3 className="detail-subtitle-text">
                    {displayAsMoney(jobDetails?.grossWeeklyPay)}
                  </h3>
                </div>
                <div className="detail-item">
                  <img
                    className="detail-icon"
                    src={getShiftDisplay(jobDetails?.shiftCategory as number).icon}
                    alt=""
                  ></img>
                  <p className="detail-item-text">
                    {getShiftDisplay(jobDetails?.shiftCategory as number).detailText}
                  </p>
                </div>
                <div className="detail-item">
                  <img className="detail-icon" src={clock_icon} alt=""></img>
                  <p className="detail-item-text">{jobDetails?.shift}</p>
                </div>
                <div className="detail-item">
                  <img className="detail-icon" src={people_icon} alt=""></img>
                  <p className="detail-item-text-positions">
                    {jobDetails?.availablePositions}
                  </p>
                  {jobDetails?.availablePositions === 1 ? (
                    <p className="detail-item-text">Opening</p>
                  ) : (
                    <p className="detail-item-text">Openings</p>
                  )}
                </div>
                <div className="detail-item-with-helper-text">
                  <div className="detail-item">
                    <img
                      className="detail-icon"
                      src={calendar_icon}
                      alt=""
                    ></img>
                    <p className="detail-item-text">
                      {jobDetails.duration} Weeks. Starts{" "}
                      {displayAsDate(jobDetails.startDate)}*
                    </p>
                  </div>
                  <p className="helper-text">
                    * In some cases, start dates can be flexible
                  </p>
                </div>
              </div>
              </div>      
                <div ref={topDivRef} className="buttonRow" data-testid="button-row">
                {!isArchivedJob(jobDetails) && !props.neverShowActionButtons &&
                  <>
                    <AddToFavoriteButton showLogin = {showLoginDialog}  jobDetails={jobDetails} postFavoriteChange={postFavoriteJob} />
                    <StartApplicationButton processing={applicationProcessing} jobDetails={jobDetails}  showStartApplicationModal={showStartApplicationModal}/>
                  </>
                }
                </div>
              <div ref={altDivRef} className="job-alt-main-header" data-testid="job-alt-main-header-container">
                <JobAltMainHeader neverShowActionButtons={props.neverShowActionButtons}
                    jobDetailsModel={jobDetails}
                    showLoginDialog={showLoginDialog}
                    postFavoriteChange={postFavoriteJob}
                    showStartApplicationModal={showStartApplicationModal}
                    processing={applicationProcessing}
                />
              </div>
            {props.submittedJobDetails && 
              <SubmittedJobDetails isOffer={false} submittedJobDetail={props.submittedJobDetails} 
            />}

            <JobSearchPayDetails jobDetails={jobDetails} />
            <JobSearchFacilityDetails jobDetails={jobDetails}/>
            <JobSearchMinimumRequirementsDetails jobDetails={jobDetails}/>
            <JobSearchCityInfoDetails  jobDetails={jobDetails} />
            <JobSearchBenefitInfoDetails jobDetails={jobDetails} data-testid="benefit-info-details"/>
            <JobSearchAboutTrsDetails data-testid="about-trs-details" jobDetails={jobDetails} />
              
          </div>
        </div>
      )}
    <LoginModal isVisible={showLoginModalVisibility} setVisibility={(value: boolean) => setShowLoginModalVisibility(value)} successfulLogin={loginCallback} isLoginForm={false} headerText={"Log into your account or sign up below"} canBeDismissed={true}/>
    </div>
    </>
  );
}