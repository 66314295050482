import { Session } from "../utilities/Session";

const TPCoreAPIDocuments = {
    getSignedContractDocument: async (contractNum: number, documentMasterId: number) => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}` } };
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/Contract/getSignedContractDocument?contractNum=${contractNum}&documentMasterId=${documentMasterId}`,
            options
        );
        return response.ok ? await response.blob() : Promise.reject({});
    },

    getContractDocumentImages: async (contractNum: number, documentMasterId: number): Promise<string[]> => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}` } };
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/Contract/getContractDocumentImages?contractNum=${contractNum}&documentMasterId=${documentMasterId}`,
            options
        );
        return response.ok ? await response.json() : Promise.reject({});
    },

    getPoliciesAndProceduresDocument: async (fileName: string) => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}` } };
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/Document/getPolicyAndProcedureDocuments?documentName=${fileName}`,
            options
        );
        return response.ok ? await response.blob() : Promise.reject({});
    },

    
}

export default TPCoreAPIDocuments;