import { useContext } from "react"
import FeatureFlagContext from "../tp-core-types/FeatureFlagsModel";
import SubmittedJobResultsAndOffers from "./SubmittedJobResultsAndOffers";
import SubmittedJobResultsSubmissionsOnly from "./SubmittedJobResultsSubmissionsOnly";

export default function SubmittedJobResults() {
    const ff = useContext(FeatureFlagContext);

    return (
       <>
       {ff.featureFlag.featureFlag_DashboardOffersAndSubmissions_IsEnabled !== undefined &&
        <>
            { ff.featureFlag.featureFlag_DashboardOffersAndSubmissions_IsEnabled ? <SubmittedJobResultsAndOffers/> : <SubmittedJobResultsSubmissionsOnly/> }
        </>
       }
       </>
    )
}