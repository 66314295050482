import { ChangeEvent, useContext, useEffect, useState } from "react";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import NavigationHeader from "../navigation-header/NavigationHeader";
import UserInfoContext from "../../tp-core-types/UserInfoModel";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { HshChecklistItem, HshChecklistItemsResponse } from "../../tp-core-types/HshChecklistItems";
import { useError } from "../../utilities/context/useError";
import "./SkillsChecklist.scss"

export default function SkillsChecklist (){
       const userInfo = useContext(UserInfoContext);

       const [checklistOptions, setChecklistOptions] = useState<HshChecklistItem[]>();
       const [selectedChecklistIds, setSelectedChecklistIds] = useState<string []>([]);
       const {setError} = useError();

       const isMobile = window.matchMedia("(max-width: 800px)").matches;


       const getSkillsChecklist = async () => {
            try {
                const hcpHshItems: HshChecklistItemsResponse = await TPCoreAPI.getHshChecklistItems();
                setChecklistOptions(hcpHshItems.hshItems);
            } catch {
                setError(new Error("It looks like something went wrong. Please try again."));
            }            
        }

        const updateSelectedChecklists = (checkboxEvent: ChangeEvent<HTMLInputElement>) => {
            const wasChecked = checkboxEvent.target.checked;
            const tempSelectedChecklistIds = selectedChecklistIds;

            if(wasChecked) {
                tempSelectedChecklistIds.push(checkboxEvent.target.value);
            }
            else {
                const deselectedIndex = tempSelectedChecklistIds.indexOf(checkboxEvent.target.value)
                if(deselectedIndex !== -1) {
                    tempSelectedChecklistIds.splice(deselectedIndex, 1);
                }
            }

            setSelectedChecklistIds(tempSelectedChecklistIds)
        }

        const postSkillsChecklists = async () => {
            await TPCoreAPI.assignHcpSkillsChecklists({checklistIds: selectedChecklistIds});
        }

        useEffect(() => {
            getSkillsChecklist();
        }, [])

    return <div id="SkillsChecklist">
        <NavigationHeader selectedStep={NavigationSteps.Skills_Checklist}  showNavigationBar={false}/>
        <div className="application-page-container">
            <div className="application-sticky-header">
                <div className="skills-title">Skills Checklist</div>
            </div>
            <div className="checklist-container">
                <div className="container-description">{userInfo.userInfo.firstName}, based on your work history, you qualify for the following skills checklist(s):</div>
                <div className="checklist-subContainer">
                {checklistOptions &&
                    <div className="checklist-item-container" data-testid="checklist-item-container">
                        {checklistOptions.map((checklistItem) => {
                            return(
                                <div className="checklist-item">
                                    <input className="application-checkbox" type="checkbox" id={`${checklistItem.discipline} - ${checklistItem.specialty}`} value={checklistItem.hshItemId ?? ""} onChange={ e => updateSelectedChecklists(e)}></input>
                                    <label htmlFor={`${checklistItem.discipline} - ${checklistItem.specialty}`} data-testid = {checklistItem.specialty}  className="checklist-item-name">
                                        {checklistItem.discipline} - {checklistItem.specialty}
                                        {/* {isMobile && <>{checklistItem.specialty}</>} */}
                                        {/* {!isMobile && <>{checklistItem.discipline} - {checklistItem.specialty}</>} */}
                                    </label>                                  
                                </div>
                            );
                        })}    
                    </div>
                }    
                </div>
            </div>
      
            <div className="action-button take-all-button" onClick={postSkillsChecklists}>Take Selected Checklists</div>
          
        </div>
    </div>;
}

