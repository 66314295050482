import { useNavigate } from "react-router-dom";
import { DashboardJobOffer, DashboardOnboarding } from "../../tp-core-types/DashboardOnboardingContractOfferResult";
import DashboardOffer from "./dashboard-submission-container/DashboardOffer";
import "./GenericDashboardJobContainer.scss"
import { useEffect, useState } from "react";
import DashboardSubmission from "./dashboard-submission-container/DashboardSubmission";
import { DashboardJobSubmission } from "../../tp-core-types/DashboardJobSubmission";
import DashboardJobOnboarding from "./dashboard-submission-container/DashboardOnboarding";

const getIsMobile = (): boolean => {
    return window.matchMedia("(max-width: 1250px)").matches;
}

type Props = {
    jobSubmissions: DashboardJobSubmission[],
    jobOffers: DashboardJobOffer[],
    jobOnboardings: DashboardOnboarding[]
}

export default function GenericDashboardJobContainer(props: Props) {
    
    const hasSignableContractForOffer = () => {
        if(!props.jobOffers)
            return true;

        return props.jobOffers.length > 0 && props.jobOffers[0].contractId !== undefined && props.jobOffers[0].contractId !== null && props.jobOffers[0].isSignable === true
    }

    const [isMobile, setIsMobile] = useState(getIsMobile());
    const submissionTitle = "Submission Status";
    const offersTitle = "Current Offers";
    const mobileOffersTitle = hasSignableContractForOffer() ? "Your contract is ready to sign!": `You have ${props.jobOffers.length} offer${props.jobOffers.length > 1 ? 's' : ''}!`
    const navigate = useNavigate();
    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);
        //clean up after the component umounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])
    
    const sortedJobSubmissions = () => {
        return props.jobSubmissions.sort((a, b) => {
            const recordADate = new Date(a.requestDate);
            const recordBDate = new Date(b.requestDate);

            return recordADate < recordBDate ? 1 : -1
        })
    }

    return(
        <>
        {props.jobOnboardings && props.jobOnboardings.length > 0 &&
            <div id="GenericDashboardOnboardingContainer" data-testid="onboardings-dashboard-container">
                <div className="onboarding-container-body">
                    {props.jobOnboardings.map((onboarding) => {
                        return (
                            <DashboardJobOnboarding key={onboarding.contractId} dashboardOnbording={onboarding}/>
                        )
                    })}
                </div>
            </div>
        }
        {props.jobOffers && props.jobOffers.length > 0 && 
        
            <div id="GenericDashboardJobContainer" data-testid="offers-dashboard-container">
                <div className="container-header">
                    
                    {!isMobile &&
                        <>
                            <div className="dashboard-job-header">{offersTitle}</div>
                            <div className="dashboard-job-count">{props.jobOffers.length} Active Offer{props.jobOffers.length > 1 && 's'}</div>
                        </>
                    }
                    {isMobile &&
                        <>
                            <div className="dashboard-job-header">{mobileOffersTitle}</div>
                        </>
                    }
                    
                </div>
                <div className="container-body">
                    {props.jobOffers.slice(0,2).map((record: DashboardJobOffer) => {
                        return (                             
                            <DashboardOffer dashboardOffer={record} key={record.jobId}></DashboardOffer>
                        );
                    }
                    )}
                    {props.jobOffers.length > 2 && 
                        <>
                            <div className="view-all-offers-btn" onClick={()=> navigate("/submitted-jobs#offers")}>View All Offers</div>
                        </>
                    }
                </div>
            </div>
            
        }
        {props.jobSubmissions && props.jobSubmissions.length > 0 &&
            <div id="GenericDashboardJobSubmissionContainer" data-testid="submissions-dashboard-container">
                <div className="container-header">
                    {!isMobile &&
                        <>
                            <div className="dashboard-job-header">{submissionTitle}</div>
                            <div className="dashboard-job-count">{props.jobSubmissions.length} Active Submission{props.jobSubmissions.length > 1 && 's'}</div>
                        </>
                    }
                    {isMobile &&
                        <>
                            <div className="dashboard-job-header">You have {props.jobSubmissions.length} submission{props.jobSubmissions.length > 1 && 's'}!</div>
                        </>
                    }
                    
                </div>
                <div className="container-body">
                    {sortedJobSubmissions().slice(0,2).map((record: DashboardJobSubmission) => {
                        return (                             
                            <DashboardSubmission dashboardSubmission={record} key={record.jobId}></DashboardSubmission>
                        );
                    }
                    )}
                    {props.jobSubmissions.length > 2 && 
                            <>
                                <div className="view-all-offers-btn" onClick={()=> navigate("/submitted-jobs#submissions")}>View All Submissions</div>
                            </>
                    }
                </div>
            </div>
        }
        </>

        
    );
}

